import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Heading } from 'rebass/styled-components';
import { rgba } from 'polished';
import { themeColor } from '../../../site-config';
import Layout from '../../components/layout/layout';
import { ContentOverlayBanner } from '../../components/banner';

const OfflinePage = ({ data }) => {
  const image = {
    src: data.bannerImg.gatsbyImageData,
    alt: 'NYC',
  };
  return (
    <Layout>
      <ContentOverlayBanner image={image} contentAlign="center" orient="left" imagePosition="center" bg={rgba(themeColor, 0.2)} fullscreen>
        <Heading fontWeight={300} fontSize="5rem" lineHeight={1}>You seem to be offline</Heading>
      </ContentOverlayBanner>
    </Layout>
  );
};

OfflinePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default OfflinePage;

export const query = graphql`
  query OfflineQuery {
    bannerImg: file(relativePath: { eq: "nyc.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }
`;
